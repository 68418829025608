import React from "react"

export const demos = {
  soundcloud:
    '<iframe style="position:absolute; top:0; left:0; width:100%; height:100%;" src="/portfolio/lakefront/index.html" name="TOUR NAME" width="100%" height="100%" frameborder="0" allowfullscreen="true" allow="fullscreen; accelerometer; gyroscope; magnetometer; vr"></iframe>',
}

export default function Iframe(props) {
  return (
    <div
      style={{ position: "relative", height: "0", paddingTop: "100%" }}
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  )
}
