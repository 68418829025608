import React from "react"
import QueueAnim from "rc-queue-anim"
import TweenOne from "rc-tween-one"
import { Row, Col } from "antd"
import OverPack from "rc-scroll-anim/lib/ScrollOverPack"

import Image from "../components/image"

function Content2(props) {
  const { ...tagProps } = props
  const { dataSource, isMobile } = tagProps
  delete tagProps.dataSource
  delete tagProps.isMobile
  const animType = {
    queue: isMobile ? "bottom" : "left",
    one: isMobile
      ? {
          scaleY: "+=0.3",
          opacity: 0,
          type: "from",
          ease: "easeOutQuad",
        }
      : {
          x: "+=30",
          opacity: 0,
          type: "from",
          ease: "easeOutQuad",
        },
  }
  const img = (
    <TweenOne
      key="img"
      animation={animType.one}
      resetStyle
      {...dataSource.imgWrapper}
      component={Col}
      componentProps={{
        md: dataSource.imgWrapper.md,
        xs: dataSource.imgWrapper.xs,
      }}
    >
      <span {...dataSource.img}>
        {/* <img src={dataSource.img.children} width="100%" alt="virtual start" /> */}
        <Image width="100%" alt="virtual start" />
      </span>
    </TweenOne>
  )
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row} id="product">
        {isMobile && img}
        <QueueAnim
          type={animType.queue}
          key="text"
          leaveReverse
          ease={["easeOutCubic", "easeInCubic"]}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          <div key="h1" {...dataSource.title}>
            {dataSource.title.children}
          </div>
          <div key="p" {...dataSource.content}>
            <strong>{dataSource.content.children}</strong>
            <br />
            <p className="content2-strong">
              All Virtual tours projects are carefully designed to inspire more
              clients and ensure tangible and trackable marketing results.
              Virtual Start are far more than a virtual tour company – we are IT
              experts who combine VR expertise with marketing strategy. <br />
            </p>
            {/* <strong className="content2-strong">
              We can produce virtual tours for museums, pubs, airplanes, boats,
              hotels, spas, wedding venues, houses, rentals, lodges, static
              caravans, pretty much anything you walk around.
            </strong> */}
          </div>
        </QueueAnim>
        {!isMobile && img}
      </OverPack>
    </div>
  )
}

export default Content2
