import React from "react"
import QueueAnim from "rc-queue-anim"
import TweenOne from "rc-tween-one"
import { Row, Col } from "antd"
import OverPack from "rc-scroll-anim/lib/ScrollOverPack"

// import Image from "../components/image"
import Video from "../components/video"

function Content1(props) {
  const { ...tagProps } = props
  const { dataSource, isMobile } = tagProps
  delete tagProps.dataSource
  delete tagProps.isMobile
  const animType = {
    queue: isMobile ? "bottom" : "right",
    one: isMobile
      ? {
          scaleY: "+=0.3",
          opacity: 0,
          type: "from",
          ease: "easeOutQuad",
        }
      : {
          x: "-=30",
          opacity: 0,
          type: "from",
          ease: "easeOutQuad",
        },
  }
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          <span {...dataSource.img}>
            {/* <img src={dataSource.img.children} width="100%" alt="img" /> */}
            {/* <Image width="100%" alt="virtual start" /> */}
            <div style={{ textAlign: "center" }}>
              <Video
                videoSrcURL="https://www.youtube.com/embed/Qg79HAyN_h4"
                videoTitle="360° video - Drag the mouse to look around"
              />
            </div>
          </span>
        </TweenOne>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={["easeOutQuad", "easeInQuad"]}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          <h2 key="h1" {...dataSource.title}>
            {dataSource.title.children}
          </h2>
          <div key="p" {...dataSource.content}>
            <strong>
              Embed into your website <br />
            </strong>
            {dataSource.content.children}
            <strong>
              <br />
              Post on social media <br />
            </strong>
            <p className="content1-strong">
              {" "}
              Virtual tours can be shared on Facebook, Twitter, LinkedIn,
              WhatsApp, Telegram, Quora and more!
            </p>
          </div>
        </QueueAnim>
      </OverPack>
    </div>
  )
}

export default Content1
