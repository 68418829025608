import React from "react"
import Container from "../components/container"
// import Image from "../components/image"
import SEO from "../components/seo"
import Home from "../Home"
import { Helmet } from "react-helmet"

const IndexPage = () => (
  <Container>
    <SEO
      title="3D Virtual Tours"
      description="Virtual Start combine the highest quality 360° photography with world-class technology to make the best 3D virtual tours and immerse virtual reality experience."
    />
    <Home />
    <Helmet>
      <script type="text/javascript">
        {`var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
            (function () {var s1 = document.createElement("script");
            s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = "https://embed.tawk.to/5fdfebf9a8a254155ab50cf7/1eq1b3i27";
            s1.charset = "UTF-8";
            s1.setAttribute("crossorigin", "*");
            s0.parentNode.insertBefore(s1, s0);
          })();`}
      </script>
    </Helmet>
  </Container>
)

export default IndexPage
