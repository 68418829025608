import React from "react"
import { Button } from "antd"
import { DownOutlined } from "@ant-design/icons"
import QueueAnim from "rc-queue-anim"
import TweenOne from "rc-tween-one"

import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa"

class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props
    const { dataSource } = currentProps
    delete currentProps.dataSource
    delete currentProps.isMobile
    return (
      <div {...currentProps} {...dataSource.wrapper}>
        <QueueAnim
          key="QueueAnim"
          type={["bottom", "top"]}
          delay={200}
          {...dataSource.textWrapper}
        >
          <div key="title" {...dataSource.title}>
            <img src={dataSource.title.children} width="100%" alt="img" />
          </div>
          <h1 key="content" {...dataSource.content}>
            {dataSource.content.children}
          </h1>
          <h3>
            <a
              href="https://www.linkedin.com/company/virtualstart"
              style={{ color: "white", backgroundColor: "#007bb5" }}
            >
              <FaLinkedinIn />
            </a>{" "}
            <a
              href="https://www.facebook.com/VirtualStart3D"
              style={{ color: "white", backgroundColor: "#3B5998" }}
            >
              <FaFacebookF />
            </a>{" "}
            <a
              href="https://twitter.com/VirtualStart"
              style={{ color: "white", backgroundColor: "#55ACEE" }}
            >
              <FaTwitter />
            </a>{" "}
            <a
              href="https://www.youtube.com/channel/UCmMz0xhTUh0HaogvmyED9UQ/featured"
              style={{ color: "white", backgroundColor: "#bb0000" }}
            >
              <FaYoutube />
            </a>{" "}
            <a
              href="https://www.linkedin.com/company/virtualstart"
              style={{ color: "white", backgroundColor: "#125688" }}
            >
              <FaInstagram />
            </a>
          </h3>
          <Button ghost key="button" {...dataSource.button} href="#product">
            {dataSource.button.children}
          </Button>
        </QueueAnim>
        <TweenOne
          animation={{
            y: "-=20",
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
          <DownOutlined />
        </TweenOne>
      </div>
    )
  }
}
export default Banner
